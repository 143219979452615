import { graphql } from "gatsby"
import React from "react"
import { Layout } from "@bw/layouts"
import { Teaser } from "@bw/partials"
import { Seo, Section, CmsImage } from "@bw/bits"
import { PageHeader, Video, Teasers } from "@bw/modules"
import useVideoCollection from "hooks/useVideoCollection"
import parse from "html-react-parser"
import { useTranslation } from "react-i18next"

const VideoTemplate = ({ data, pageContext }) => {
  const { page } = data
  const { t } = useTranslation()
  const videos = useVideoCollection()
  const topicsIds = page.topics.map(t => t.id)

  const inTopic = React.useMemo(() => {
    return videos.filter(v => {
      if (v.id === pageContext.id) {
        return false
      }

      if (!v.topics.some(t => topicsIds.includes(t.id))) {
        return false
      }

      return true
    })
  }, [videos, pageContext.id, topicsIds])

  const url =
    page.source?.value === "youtube"
      ? `https://www.youtube.com/watch?v=${page.youtubeId}`
      : page.source?.value === "vimeo"
      ? `https://vimeo.com/${page.vimeoId}`
      : null

  return (
    <Layout {...{ pageContext }}>
      <Seo
        title={page.metaTitle || page.title}
        description={page.metaDescription}
        meta={[
          {
            name: "robots",
            content: page.doNotIndex
              ? "noindex, nofollow"
              : "max-image-preview:large, index, follow",
          },
        ]}
      />

      <PageHeader title={page.title} />
      <Section>
        <Video url={url} poster={page?.poster?.url} />
      </Section>
      {page.description && <Section>{parse(page.description)}</Section>}
      {inTopic.length > 0 && (
        <Section
          title={t("inTheSameSubject")}
          subtitle={page.topics.map(t => t.title).join(", ")}
        >
          <Teasers>
            {inTopic.map((item, i) => (
              <Teaser
                key={i}
                title={item.title}
                image={
                  item.poster ? (
                    <CmsImage
                      src={item.poster}
                      alt={item.poster?.alt || item.title}
                    />
                  ) : null
                }
                to={item.url}
              />
            ))}
          </Teasers>
        </Section>
      )}
    </Layout>
  )
}

export default VideoTemplate

export const videoQuery = graphql`
  query videoQuery($id: String!) {
    page: collectionVideos(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      description
      image {
        url
      }
      doNotIndex

      source {
        value
      }
      youtubeId
      vimeoId
      poster {
        url
      }

      target {
        title
        url
      }
      topics {
        id
        title
        url
      }
    }
  }
`
